
import { onMounted, ref, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "CommunicationMediaTypesSelect",
  props: {
    media_type_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:media_type_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputMediaType = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isMediaRequired = (value) => {
      if (props.required && !value) {
        return t("rmedia");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
      "media",
      isMediaRequired
    );

    const getMediaType = () => {
      store.commit("setLoading", false);
      ApiService.get("/api/media/lists").then(({ data }) => {
        inputMediaType.value.list = data.media;
        inputMediaType.value.options = data.media;
      });
    };
    const selectMediaType = (query) => {
      if (query !== "") {
        inputMediaType.value.loading = true;
        setTimeout(() => {
          inputMediaType.value.loading = false;
          inputMediaType.value.options = inputMediaType.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputMediaType.value.options = [];
      }
    };

    watch(
      () => props.media_type_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getMediaType();
      }, 3000);
    });

    return {
      element_id,
      inputMediaType,
      errorMessage,
      getMediaType,
      selectMediaType,
    };
  },
};
