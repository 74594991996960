
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "UsersSelect",
  props: {
    user_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:user_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const inputUser = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isUserRequired = (value) => {
      if (props.required && !value) {
        return t("ruser");
      }
      return true;
    };

    const { errorMessage, value: element_id } = useField(
        "user",
        isUserRequired
    );

    const getShip = () => {
      store.commit("setLoadingStatus", true);
      store.commit("setLoading", false);
      ApiService.get("/api/users").then(({ data }) => {
        inputUser.value.list = data.users;
        inputUser.value.options = data.users;
        store.commit("setLoadingStatus", false);
      });
    };
    const selectUser = (query) => {
      if (query !== "") {
        inputUser.value.loading = true;
        setTimeout(() => {
          inputUser.value.loading = false;
          inputUser.value.options = inputUser.value.list.filter(
              (item: Record<string, any>) => {
                return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
              }
          );
          if (!inputUser.value.options.length) {
            store.commit("setLoading", false);
            store.commit("setLoadingStatus", true);
            ApiService.query(`/api/users`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputUser.value.list = data.users;
              inputUser.value.options = data.users;
              store.commit("setLoadingStatus", false);
            });
          }
        }, 200);
      } else {
        inputUser.value.options = [];
      }
    };

    watch(
        () => props.user_id,
        (first) => {
          if (first) {
            element_id.value = first;
          }
        }
    );

    onMounted(() => {
      setTimeout(function () {
        getShip();
      }, 3000);
    });

    return {
      element_id,
      inputUser,
      errorMessage,
      getShip,
      selectUser,
    };
  },
};
